import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Container from '@components/Container'
import { ButtonLink } from '@components/ButtonLink'
import { useTranslation } from 'react-i18next'
import SEO from '@components/SEO'

const SubmissionPage = () => {
	const { t } = useTranslation()

	return (
		<Container title={t('submission.title')}>
			<SEO title={t('submission.title')} keywords={[]} />
			<h1>{t('submission.heading')}</h1>
			<p>{t('submission.description')}</p>
			<ButtonLink to={'/'}>{t('back_to_home')}</ButtonLink>
		</Container>
	)
}

export default SubmissionPage

export const query = graphql`
	query Submission($language: String!) {
		...TranslationFragment
	}
`
